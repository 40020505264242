.containerSm, .subContainer {
    display: flex;
    gap: 0.5em;
}

.subContainer {
    flex-direction: column;
}

.container {
    display: flex;
    gap: 1em;
}

.containerControl {
    min-width: 100px!important;
}

.wrapper {
    display: flex;
    gap: 1em;
    justify-content: space-between;
}