.profile {
  position: relative;
}

.bubble {
  top: 50px;
  left: -177px;
  z-index: 20;
  position: absolute;
  background-color: #fff;
  border: 2px solid #2e3d6b;
  display: grid;
  text-align: center;
  width: 340px;
  border-radius: 10px;
  padding: 10px;
  transition:
    width 2s,
    height 2s,
    transform 2s;
  gap: 5px;
}

.bubble_mobile {
  left: -290px;
  top: 50px;
}

.bubbleItem {
  width: 320px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.bubbleItem__name {
  color: black;
  font-size: 14px;
  white-space: nowrap;
}

.bubbleItem__value {
  color: blue;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
}

.checkboxItem {
  padding-left: 10px;
}

.backgroundModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  opacity: 0;
  z-index: 20;
}

.version {
  color: black;
  font-size: 12px;
  white-space: nowrap;
}
