.tableContainer {
  height: 100%;
  min-width: 360px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
}

.tableContentCenter {
  font-size: 22px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  place-content: center center;
  align-items: center;
}

.tableContentColumn {
  height: 50%;
  flex-flow: column wrap;
}

.tableContent {
  overflow: hidden;
  width: 100%;

  min-height: 100px;
  height: 100%;

  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
}

.tableHeader {
  width: 100%;
  min-height: 50px;
  color: #2e3d6b;
  box-sizing: border-box;
  display: grid;
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;

  flex-shrink: 0;

  padding: 0 12px;
}

.tableBody {
  height: 100%;
  overflow: hidden auto;

  display: flex;
  flex-flow: column nowrap;
  gap: 2px;
}

.tableBodyWithFooter {
  max-height: calc(100vh - 70px);
}
