.modalBody {
  margin-top: 3px;
  width: 100%;
  font-size: 1rem;
}

.modalBodyAlert {
  margin-top: 3px;
  width: 100%;
  font-size: 1rem;
  color: #ec0000;
}
