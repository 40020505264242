.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  height: 100%;
}

.container > * {
  width: 100%;
}

.ceilHeader {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 1.1rem;
  font-weight: bold;
  height: 50px;
  padding: 0 2px;
}

.ceilText {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
}

.sortButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-left: 4px;
}
