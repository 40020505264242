.headerWrapper {
  position: relative;
  width: 100%;
}

.headerWrapper_mobile {
  padding-left: 50px;
}

.headerBody {
  width: 100%;
  min-height: 60px;

  flex-shrink: 0;

  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2px;
  padding: 5px 5px 0;
}

.headerMain {
  display: flex;
  flex-flow: row nowrap;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  height: 54px;
  padding-right: 7px;
}

.headerContent {
  max-height: 56px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  align-content: flex-start;
  padding: 7px;
  gap: 10px;
  overflow: hidden;
}

.headerContentHeader {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px;
}

.headerContentHeader__spoiler {
  position: relative;
  top: 20px;
  visibility: hidden;
}

.headerContentSubHeader {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  place-content: flex-start flex-start;
  overflow: hidden;
  gap: 10px;
  max-height: 0;
  opacity: 0;
  transition: all 0.2s ease;
  padding: 5px 12px 3px;
}

.headerContentSubHeader_open {
  max-height: 600px;
  opacity: 1;
}

.headerInstance {
  white-space: nowrap;
}

.additionalText {
  align-self: center;
}