.footerBody {
  width: 100%;
  height: 65px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 0 12px;
}
