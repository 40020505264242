.content {
  display: flex;
  align-items: center;
}

.content > button {
  margin-right: 5px;
}

.spacePoint {
  position: relative;
  margin-left: 5px;
  margin-right: 10px;
  top: 12px;
}
